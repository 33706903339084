import "./vakantie.css";
import { useParams } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import Navbar from "../components/navbar";
import VakantieItems from "./vakantieComponents/vakantieItems";
import Sidebar from "./vakantieComponents/sidebar";
import React, { useState, useEffect } from "react";
function Vakantie(props) {
  const { id } = useParams();

  const [vakantie, setVakantie] = useState({ name: "" });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("https://vakantieupdates.kianhelmink.nl/api/get_vacation.php?id=" + id);
      const data = await response.json();
      setVakantie(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const { User } = props;
  console.log(User)
  return (
    <div className="VakantiePage">
      <Navbar User={User} />
      <div className="Vakantie">
        <div className="leftBar"></div>
        <VakantieItems vakantieID={id} />
        <Sidebar vakantie={vakantie} />
        {User.id === "1" && <div className="CreateNewPostButton">
          <a href={"https://vakantieupdates.kianhelmink.nl/vakantie/" + id +"/createPost"}>Create new</a>
          </div>}
      </div>
    </div>
  );
}

export default Vakantie;
