import "./sidebar.css";
import Map from "./map";
function Sidebar(props) {
  const { vakantie } = props;
  if (vakantie.markers) {
    const markers = JSON.parse(vakantie.markers);
    const mapCenter = JSON.parse(vakantie.mapCenter);
    return (
      <div className="Sidebar">
        <h1>{vakantie.Name}</h1>
        <p>{vakantie.Year}</p>
        <Map position={mapCenter} zoom={vakantie.mapZoom} markers={markers} />
      </div>
    );
  }
  return (
    <div className="Sidebar">
      <h1>{vakantie.Name}</h1>
      <p>{vakantie.Year}</p>
    </div>
  );
}

export default Sidebar;
