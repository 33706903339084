import './button.css';

function Button(props) {
  const { onClick, Text } = props;
  return (
    <button className='button-primary' onClick={onClick}>
      {Text}
    </button>
  );
}

export default Button;
