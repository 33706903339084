import TravelMap from "./travelMap";
import Visitmap from "./visitmap";

function ImageGrid(props) {
  const { post } = props;
  console.log(post);
  const markers = JSON.parse(post.mapmarker);
  const mapCenter = JSON.parse(post.mapcenter);
  console.log(mapCenter)
  const images = [post.img1, post.img2, post.img3, post.img4, post.img5]
  const imageCount = parseInt(post.imagecount);
  const mapZoom = parseInt(post.mapzoom);
  if (post.maptype === "Travelmap")
    if (imageCount === 0) {
      return (
        <div className="ImageGrid one">
          <TravelMap position={mapCenter} zoom={mapZoom} markers={markers} />{" "}
        </div>
      );
    } else if (imageCount === 1) {
      return (
        <div className="ImageGrid two">
          <img src={images[0]} alt="" />
          <TravelMap position={mapCenter} zoom={mapZoom} markers={markers} />{" "}
        </div>
      );
    } else if (imageCount === 2) {
      return (
        <div className="ImageGrid three">
          <TravelMap position={mapCenter} zoom={mapZoom} markers={markers} />{" "}
          <img src={images[0]} alt="" />
          <img src={images[1]} alt="" />
        </div>
      );
    } else if (imageCount === 3) {
      return (
        <div className="ImageGrid four">
          <img src={images[0]} alt="" />
          <TravelMap position={mapCenter} zoom={mapZoom} markers={markers} />{" "}
          <img src={images[1]} alt="" />
          <img src={images[2]} alt="" />
        </div>
      );
    } else {
      return (
        <div className="ImageGrid five">
          <img src={images[0]} alt="" />
          <TravelMap position={mapCenter} zoom={mapZoom} markers={markers} />
          <img src={images[1]} alt="" />
          <img src={images[2]} alt="" />
          <img src={images[3]} alt="" />
        </div>
      );
    }
  else if (post.maptype === "Visitmap") {
    if (imageCount === 0) {
      return (
        <div className="ImageGrid one">
          <Visitmap position={mapCenter} zoom={mapZoom} markers={markers} />{" "}
        </div>
      );
    } else if (imageCount === 1) {
      return (
        <div className="ImageGrid two">
          <img src={images[0]} alt="" />
          <Visitmap position={mapCenter} zoom={mapZoom} markers={markers} />{" "}
        </div>
      );
    } else if (imageCount === 2) {
      return (
        <div className="ImageGrid three">
          <Visitmap position={mapCenter} zoom={mapZoom} markers={markers} />{" "}
          <img src={images[0]} alt="" />
          <img src={images[1]} alt="" />
        </div>
      );
    } else if (imageCount === 3) {
      return (
        <div className="ImageGrid four">
          <img src={images[0]} alt="" />
          <Visitmap position={mapCenter} zoom={mapZoom} markers={markers} />{" "}
          <img src={images[1]} alt="" />
          <img src={images[2]} alt="" />
        </div>
      );
    } else {
      return (
        <div className="ImageGrid five">
          <img src={images[0]} alt="" />
          <Visitmap position={mapCenter} zoom={mapZoom} markers={markers} />
          <img src={images[1]} alt="" />
          <img src={images[2]} alt="" />
          <img src={images[3]} alt="" />
        </div>
      );
    }
  } else {
    if (imageCount === 1) {
      return (
        <div className="ImageGrid one">
          <img src={images[0]} alt="" />
        </div>
      );
    } else if (imageCount === 2) {
      return (
        <div className="ImageGrid two">
          <img src={images[0]} alt="" />
          <img src={images[1]} alt="" />
        </div>
      );
    } else if (imageCount === 3) {
      return (
        <div className="ImageGrid three">
          <img src={images[0]} alt="" />
          <img src={images[1]} alt="" />
          <img src={images[2]} alt="" />
        </div>
      );
    } else if (imageCount === 4) {
      return (
        <div className="ImageGrid four">
          <img src={images[0]} alt="" />
          <img src={images[1]} alt="" />
          <img src={images[2]} alt="" />
          <img src={images[3]} alt="" />
        </div>
      );
    } else if (imageCount === 5) {
      return (
        <div className="ImageGrid five">
          <img src={images[0]} alt="" />
          <img src={images[1]} alt="" />
          <img src={images[2]} alt="" />
          <img src={images[3]} alt="" />
          <img src={images[4]} alt="" />
        </div>
      );
    } else return;
  }
}

export default ImageGrid;
