import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./createPost.css";
import Navbar from "../components/navbar";
import ImageGrid from "./createPost/imageGrid";

const placeholderImage = "https://i0.wp.com/mastare.nl/wp-content/uploads/2023/01/placeholder-285.png?ssl=1";

function CreatePost(props) {
  const { id: vakantieID } = useParams();
  const { User } = props;

  const [mapMarkerValue, setMapmarkerValue] = useState("[]");
  const [post, setPost] = useState({
    vakantieID: vakantieID,
    name: "",
    text: "",
    maptype: "none",
    img1: placeholderImage,
    img2: placeholderImage,
    img3: placeholderImage,
    img4: placeholderImage,
    img5: placeholderImage,
    imagecount: 0,
    mapcenter: "[0, 0]",
    mapmarker: "[]",
    mapzoom: 1,
  });

  useEffect(() => {
    console.log('Post state:', post);
  }, [post]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPost((prevPost) => {
      const updatedPost = { ...prevPost, [name]: value };
      if (name === "maptype") {
        if (value === "Visitmap") {
          updatedPost.mapcenter = "[12.175805, -68.983733]";
          updatedPost.mapzoom = 9;
          updatedPost.mapmarker = "[]";
        } else if (value === "Travelmap") {
          updatedPost.mapcenter = "[40.193061, -30.801906]";
          updatedPost.mapzoom = 1;
          updatedPost.mapmarker = "[[12.184516, -68.956992], [52.311676, 4.750505]]";
        }
      }
      return updatedPost;
    });
  };

  const handleImageUpload = (index) => (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPost((prevPost) => {
          const imageField = `img${index + 1}`;
          return { ...prevPost, [imageField]: reader.result };
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageCountChange = (e) => {
    const count = parseInt(e.target.value, 10);
    setPost((prevPost) => {
      const updatedPost = { ...prevPost, imagecount: count };
      for (let i = 1; i <= 5; i++) {
        if (i > count) {
          updatedPost[`img${i}`] = null;
        } else {
          updatedPost[`img${i}`] = prevPost[`img${i}`] || placeholderImage;
        }
      }
      return updatedPost;
    });
  };

  const handleMapMarkerChange = () => {
    setPost((prevPost) => ({
      ...prevPost,
      mapmarker: mapMarkerValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://vakantieupdates.kianhelmink.nl/api/create_post.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(post),
      });
      const data = await response.json();
      console.log("Response:", data);
      if (data.success) {
        alert("Post created successfully!");
      } else {
        alert("Error creating post: " + data.message);
      }
    } catch (error) {
      console.error("Error creating post:", error);
      alert("Error creating post: " + error.message);
    }
  };

  if (User.id !== "1") {
    return null;
  }

  return (
    <div className="CreatePostPage">
      <Navbar User={User} />
      <div className="CreatePostForm">
        <ImageGrid post={post} onImageUpload={handleImageUpload} />
        <h1>Create Post</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Post Name:</label>
            <input type="text" name="name" value={post.name} onChange={handleInputChange} required />
          </div>
          <div>
            <label>Post Text:</label>
            <textarea name="text" value={post.text} onChange={handleInputChange} required />
          </div>
          <div>
            <label>Map Type:</label>
            <select name="maptype" value={post.maptype} onChange={handleInputChange}>
              <option value="">None</option>
              <option value="Travelmap">Travelmap</option>
              <option value="Visitmap">Visitmap</option>
            </select>
          </div>
          {post.maptype === "Visitmap" && (
            <div>
              <label>Map Marker:</label>
              <input
                type="text"
                name="mapmarker"
                value={mapMarkerValue}
                onChange={(e) => setMapmarkerValue(e.target.value)}
                placeholder="Format: [[lat, lng], [lat, lng]]"
                required
              />
              <p className="MarkerButton" onClick={handleMapMarkerChange}>Set marker</p>
            </div>
          )}
          <div>
            <label>Image Count:</label>
            <input type="number" name="imagecount" value={post.imagecount} onChange={handleImageCountChange} min="0" max="5" required />
          </div>
          <button type="submit">Create Post</button>
        </form>
      </div>
    </div>
  );
}

export default CreatePost;
