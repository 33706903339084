import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./colors.css";
import Login from "./pages/login";
import Home from "./pages/home";
import Vakantie from "./pages/vakantie";
import Post from "./pages/post";
import CreatePost from "./pages/postCreate";
//import VacationDetails from './pages/vacationDetails'; // Create this component

const App = () => {
  /*
  const user = {
    id: "1",
    name: "Kian"
  }
    */
  const [user, setUser] = useState(null);

  if (!user) {
    return <Login setUser={setUser} />;
  }
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home User={user} />} />
        <Route path="/vakantie/:id" element={<Vakantie User={user} />} />
        <Route path="/vakantie/:id/post/:pID" element={<Post User={user} />} />
        <Route path="/vakantie/:id/createpost" element={<CreatePost User={user} />} />
      </Routes>
    </Router>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
