import React, { useState, useEffect } from 'react';
import './recentPost.css';

function RecentPost() {
  const [recentPost, setRecentPost] = useState(null);

  useEffect(() => {
    fetch('https://vakantieupdates.kianhelmink.nl/api/get_newestPost.php')
      .then(response => response.json())
      .then(data => setRecentPost(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="mostRecentPost">
      <h1>Nieuwste post:</h1>
      {recentPost && (
        <div className="recentPost">
          <div>
            <h2>{recentPost.name}</h2>
            <p>
            {recentPost.text
              .replace(/\\n/g, "\n")
              .split("\n")
              .map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
          </p>
          </div>
          <img src={recentPost.img1} alt=""/>
        </div>
      )}
    </div>
  );
}

export default RecentPost;
