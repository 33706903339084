import "./navbar.css";
import DefaultUser from "../assets/img/defaultUser.png";
import Logo from "../assets/img/logo.png";
function Navbar(props) {
  const { User } = props;
  console.log(User)
  return (
    <div className="navbarOuter">
      <div className="navbar">
        <div>
          <img className="Logo" src={Logo} alt="" />
          <h1>Vakantie Updates</h1>
        </div>
        <div>
          <h2>{User.name}</h2>
          <img className="profilePic" src={User.imgSrc ? User.imgSrc : DefaultUser} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
