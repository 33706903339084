import "leaflet/dist/leaflet.css";

import { MapContainer } from "react-leaflet/MapContainer";
import { TileLayer } from "react-leaflet/TileLayer";
import { Marker, Popup, Polyline } from "react-leaflet";
import markerIMG from "./marker.png";

import L from "leaflet"; // Import leaflet library

const markerIcon = L.icon({
  iconUrl: markerIMG, // Provide the path to your custom icon
  iconSize: [25, 25], // Size of the icon
  iconAnchor: [12.5, 25], // Point of the icon which will correspond to marker's location
  popupAnchor: [0, -40], // Point from which the popup should open relative to the iconAnchor
});

function Map(props) {
  const { position, zoom, markers } = props;

  const markerItems = markers.map(marker => (
    <Marker  position={marker} icon={markerIcon}>
    </Marker>
  ));


  return (
    <div className="Map">
      <MapContainer center={position} zoom={zoom}  zoomControl={false} scrollWheelZoom={true} fullscreen={true}>
        <TileLayer
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png"
        />
        {markerItems}
        <Polyline positions={markers} smoothFactor={2} />
      </MapContainer>
    </div>
  );
}

export default Map;
