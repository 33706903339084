import "./vacations.css";
import Vacation from "../vacationsComponents/vacation";
import React, { useState, useEffect } from 'react';

function Vacations() {
  const [vacationData, setVacationData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("https://vakantieupdates.kianhelmink.nl/api/get_vacations.php");
      const data = await response.json();
      setVacationData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="vacations">
      <h1>Vakanties:</h1>
      <div className="vacayGrid">
        {vacationData.map((vacation) => (
          <Vacation key={vacation.id} vacation={vacation} />
        ))}
      </div>
    </div>
  );
}

export default Vacations;
