import './passInput.css';

function PassInput(props) {
  const { Placeholder, value, onChange } = props;
  return (
    <input
      className="passInput"
      type='password'
      placeholder={Placeholder}
      value={value}
      onChange={onChange}
    />
  );
}

export default PassInput;
