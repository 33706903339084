import "./vakantieItems.css";
import Post from "./post";
import React, { useState, useEffect } from "react";

function VakantieItems(props) {
  const { vakantieID } = props;

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://vakantieapi.kianhelmink.nl/get_posts.php?id=" + vakantieID
      );
      const data = await response.json();
      setPosts(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="vakantieItems">
      {posts && posts.map((post) => <Post key={post.id} post={post} />)}
    </div>
  );
}

export default VakantieItems;

