import './login.css';
import LoginWindow from "./loginComponents/loginWindow";

function Login(props) {
  const {setUser} = props
  return (
    <div className="Login">
        <LoginWindow setUser={setUser} />
    </div>
  );
}

export default Login;
