import React from "react";
import TravelMap from "../postComponents/travelMap";
import Visitmap from "../postComponents/visitmap";


function ImageGrid({ post, onImageUpload }) {
  const markers = JSON.parse(post.mapmarker);
  const mapCenter = JSON.parse(post.mapcenter);
  const images = [post.img1, post.img2, post.img3, post.img4, post.img5]
  const imageCount = parseInt(post.imagecount, 10);
  const mapZoom = parseInt(post.mapzoom, 10);

  const renderImageSlot = (index) => (
    <div key={index} className="ImageSlot" onClick={() => document.getElementById(`file-input-${index}`).click()}>
      <img src={images[index]} alt={`Upload ${index}`} />
      <input type="file" id={`file-input-${index}`} style={{ display: "none" }} accept="image/*" onChange={onImageUpload(index)} />
    </div>
  );

  const renderGrid = () => {
    if (post.maptype === "Travelmap") {
      if (imageCount === 0) {
        return (
          <div className="ImageGrid one" key="grid-travelmap">
            <TravelMap position={mapCenter} zoom={mapZoom} markers={markers} />
          </div>
        );
      } else if (imageCount === 1) {
        return (
          <div className="ImageGrid two" key="grid-travelmap">
            {renderImageSlot(0)}
            <TravelMap position={mapCenter} zoom={mapZoom} markers={markers} />
          </div>
        );
      } else if (imageCount === 2) {
        return (
          <div className="ImageGrid three" key="grid-travelmap">
            <TravelMap position={mapCenter} zoom={mapZoom} markers={markers} />
            {renderImageSlot(0)}
            {renderImageSlot(1)}
          </div>
        );
      } else if (imageCount === 3) {
        return (
          <div className="ImageGrid four" key="grid-travelmap">
            {renderImageSlot(0)}
            <TravelMap position={mapCenter} zoom={mapZoom} markers={markers} />
            {renderImageSlot(1)}
            {renderImageSlot(2)}
          </div>
        );
      } else {
        return (
          <div className="ImageGrid five" key="grid-travelmap">
            {renderImageSlot(0)}
            <TravelMap position={mapCenter} zoom={mapZoom} markers={markers} />
            {renderImageSlot(1)}
            {renderImageSlot(2)}
            {renderImageSlot(3)}
          </div>
        );
      }
    } else if (post.maptype === "Visitmap") {
      if (imageCount === 0) {
        return (
          <div className="ImageGrid one" key="grid-visitmap">
            <Visitmap position={mapCenter} zoom={mapZoom} markers={markers} />
          </div>
        );
      } else if (imageCount === 1) {
        return (
          <div className="ImageGrid two" key="grid-visitmap">
            {renderImageSlot(0)}
            <Visitmap position={mapCenter} zoom={mapZoom} markers={markers} />
          </div>
        );
      } else if (imageCount === 2) {
        return (
          <div className="ImageGrid three" key="grid-visitmap">
            <Visitmap position={mapCenter} zoom={mapZoom} markers={markers} />
            {renderImageSlot(0)}
            {renderImageSlot(1)}
          </div>
        );
      } else if (imageCount === 3) {
        return (
          <div className="ImageGrid four" key="grid-visitmap">
            {renderImageSlot(0)}
            <Visitmap position={mapCenter} zoom={mapZoom} markers={markers} />
            {renderImageSlot(1)}
            {renderImageSlot(2)}
          </div>
        );
      } else {
        return (
          <div className="ImageGrid five" key="grid-visitmap">
            {renderImageSlot(0)}
            <Visitmap position={mapCenter} zoom={mapZoom} markers={markers} />
            {renderImageSlot(1)}
            {renderImageSlot(2)}
            {renderImageSlot(3)}
          </div>
        );
      }
    } else {
      if (imageCount === 0) {return}
      if (imageCount === 1) {
        return (
          <div className="ImageGrid one" key="grid-visitmap">
            {renderImageSlot(0)}
          </div>
        );
      }else if (imageCount === 2) {
        return (
          <div className="ImageGrid two" key="grid-visitmap">
            {renderImageSlot(0)}
            {renderImageSlot(1)}

          </div>
        );
      }else if (imageCount === 3) {
        return (
          <div className="ImageGrid three" key="grid-visitmap">
            {renderImageSlot(0)}
            {renderImageSlot(1)}
            {renderImageSlot(2)}


          </div>
        );
      }else if (imageCount === 4) {
        return (
          <div className="ImageGrid four" key="grid-visitmap">
            {renderImageSlot(0)}
            {renderImageSlot(1)}
            {renderImageSlot(2)}
            {renderImageSlot(3)}
          </div>
        );
      }else{
        return (
          <div className="ImageGrid five" key="grid-visitmap">
            {renderImageSlot(0)}
            {renderImageSlot(1)}
            {renderImageSlot(2)}
            {renderImageSlot(3)}
            {renderImageSlot(4)}

          </div>
        );
      }
    }
  };

  return renderGrid();
}

export default ImageGrid;
