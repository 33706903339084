import "./loginWindow.css";
import { useState, useRef, useEffect } from "react";
import TextInput from "../../components/textInput";
import PassInput from "../../components/passInput";
import Button from "../../components/button";
import DefaultUser from "../../assets/img/defaultUser.png";
import axios from "axios";

function LoginWindow({ setUser }) {
  const [window, setWindowState] = useState("Login");
  const [profileImage, setProfileImage] = useState(DefaultUser);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [key, setKey] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const fileInputRef = useRef(null);
  const [error, setError] = useState();

  useEffect(() => {
    const savedUsername = localStorage.getItem("rememberedUsername");
    const savedPassword = localStorage.getItem("rememberedPassword");
    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
      handleLogin(savedUsername, savedPassword);
    }
  }, []);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      setProfileImage(event.target.result); // Set profileImage state to Base64 string
    };

    reader.readAsDataURL(file);
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleLogin = async (savedUsername = username, savedPassword = password) => {
    try {
      const response = await axios.post("https://vakantieupdates.kianhelmink.nl/api/login.php", {
        username: savedUsername,
        password: savedPassword,
      });
      if (response.data.success) {
        if (rememberMe) {
          localStorage.setItem("rememberedUsername", savedUsername);
          localStorage.setItem("rememberedPassword", savedPassword);
        } else {
          localStorage.removeItem("rememberedUsername");
          localStorage.removeItem("rememberedPassword");
        }
        setUser({
          name: response.data.user.username,
          imgSrc: response.data.user.profileImage,
          id: response.data.user.id,
        });
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError("An error occurred while logging in. Please try again.");
    }
  };

  const handleRegister = async () => {
    try {
      const response = await axios.post("https://vakantieapi.kianhelmink.nl/register.php", {
        username,
        password,
        key,
        profileImage, // Send Base64 string to the server
      });
      if (response.data.success) {
        setUser({
          name: response.data.user.username,
          imgSrc: response.data.user.profileImage,
          id: response.data.user.id,
        });
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError("An error occurred while registering. Please try again.");
    }
  };

  const Register = (
    <div className="LoginWindow">
      <img src={profileImage} alt="" onClick={handleImageClick} />
      <input type="file" accept="image/*" style={{ display: "none" }} ref={fileInputRef} onChange={handleImageUpload} />
      <p>Username: </p>
      <TextInput Placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
      <p>Password: </p>
      <PassInput Placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <p>Key: </p>
      <PassInput Placeholder="Key" value={key} onChange={(e) => setKey(e.target.value)} />
      <Button Text="Register" onClick={handleRegister} />
      {error && <p className="Error">{error}</p>}
      <p className="TextButton" onClick={() => setWindowState("Login")}>
        Already have an account?
      </p>
    </div>
  );

  const Login = (
    <div className="LoginWindow">
      <img src={DefaultUser} alt="" />
      <p>Username: </p>
      <TextInput Placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
      <p>Password: </p>
      <PassInput Placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <div>
        <input
          type="checkbox"
          className="CheckBox"
          checked={rememberMe}
          onChange={(e) => setRememberMe(e.target.checked)}
        />
        <p>Remember me</p>
      </div>
      <Button Text="Login" onClick={() => handleLogin()} />
      {error && <p className="Error">{error}</p>}
      <p className="TextButton" onClick={() => setWindowState("Register")}>
        Don't have an account?
      </p>
    </div>
  );

  return <div>{window === "Login" ? Login : Register}</div>;
}

export default LoginWindow;
