import "./post.css";
import Navbar from "../components/navbar";
import ImageGrid from "./postComponents/imageGrid";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";

function Post(props) {
  const { User } = props;
  const { pID } = useParams();

  const [post, setPost] = useState({ name: "", id: pID, text: "", maptype: "", imagecount: "1", mapcenter: "[0, 0]", mapmarker: "[]", mapzoom: 0 });
  const [reactions, setReactions] = useState([]);
  const [reactionText, setReactionText] = useState("");

  useEffect(() => {
    fetchData();
    fetchReactions();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("https://vakantieupdates.kianhelmink.nl/api/get_post.php?id=" + pID);
      const data = await response.json();
      setPost(data);
    } catch (error) {
      console.error("Error fetching post data:", error);
    }
  };

  const handleReactionSubmit = async () => {
    try {
      const response = await fetch("https://vakantieupdates.kianhelmink.nl/api/add_reaction.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: User.id,
          post_id: pID,
          text: reactionText,
        }),
        credentials: "omit",
      });
      if (response.ok) {
        setReactionText("");
        fetchReactions(); // Refresh reactions
      } else {
        console.error("Failed to submit reaction:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting reaction:", error);
    }
  };

  const fetchReactions = async () => {
    try {
      const response = await fetch("https://vakantieupdates.kianhelmink.nl/api/get_reactions.php?post_id=" + pID, {
        method: "GET",
        credentials: "omit",
      });
      const data = await response.json();
      setReactions(data);
    } catch (error) {
      console.error("Error fetching reactions:", error);
    }
  };

  return (
    <div className="PostPage">
      <Navbar User={User} />
      <div className="PostInfo">
        <ImageGrid post={post} />
        <h1>{post.name}</h1>
        <p>
          {post.text
            .replace(/\\n/g, "\n")
            .split("\n")
            .map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
        </p>
        <div className="Line"></div>
        <div className="Reactions">
          <input type="text" value={reactionText} onChange={(e) => setReactionText(e.target.value)} placeholder="Leave a reaction" />
          <button onClick={handleReactionSubmit}>Submit</button>
        </div>
        <div className="ReactionList">
          {reactions.map((reaction, index) => (
            <div key={index} className="Reaction">
              <div className="User">
                <img src={reaction.userprofilepic} alt="Profile" />
                <p>{reaction.username}</p>
              </div>
              <p>{reaction.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Post;
