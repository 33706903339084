import "./post.css";
import { Link } from "react-router-dom";
function Post(props) {
  const { post } = props;
  return (
    <Link to={"./post/" + post.id}>
      <div className="Post">
        <div>
          <h2>{post.name}</h2>
          <p>
            {post.text
              .replace(/\\n/g, "\n")
              .split("\n")
              .map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
          </p>
        </div>
        <img src={post.img1} alt="" />
      </div>
    </Link>
  );
}

export default Post;
