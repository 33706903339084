import './textInput.css';

function TextInput(props) {
  const { Placeholder, value, onChange } = props;
  return (
    <input
      className="textInput"
      type='text'
      placeholder={Placeholder}
      value={value}
      onChange={onChange}
    />
  );
}

export default TextInput;
