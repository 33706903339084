import "./home.css";
import Navbar from "../components/navbar";
import RecentPost from "./homeComponents/recentPost";
import Vacations from "./homeComponents/vacations";

function Home(props) {

  const { User } = props;
  return (
    <div className="Home">
      <Navbar User={User} />
      <div className="Content">
        <RecentPost />
        <Vacations />
      </div>
    </div>
  );
}

export default Home;
