import "./vacation.css";
import { Link } from "react-router-dom";
function Vacation(props) {
  const { vacation } = props;
  return (
    <Link to={"./vakantie/" + vacation.id}>
      <div className="Vacation" style={{ backgroundImage: `url('${vacation.bannerSrc}')` }}>
        <div className="VacationInfo">
          <h3>{vacation.Name}</h3>
          <p>{vacation.Year}</p>
        </div>
      </div>
    </Link>
  );
}

export default Vacation;
